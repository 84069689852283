import { useQuery } from '@tanstack/react-query'
import { ThreadsService } from '@/services/inbox/threads.api'
import { useStore } from '@/shared/store'
import { useEffect, useState } from 'react'
import { IThread } from '@/shared/types/threads.interface'

export const useGetThread = () => {
    const [enabled, setEnabled] = useState<boolean>(false)
    const { thread, setThread, selectedThreadId, setSelectedThreadId } = useStore()

    const queryResult = useQuery(
        ['get_thread'],
        () => ThreadsService.getThread(selectedThreadId as string | number),
        {
            enabled,
            onSuccess(res) {
                console.log('res.data', res.data)
                setThread((res.data as IThread) || {})
            }
        }
    )

    useEffect(() => {
        enabled && setEnabled(false)
    }, [enabled])

    const fetchThread = (id: string | number) => {
        setSelectedThreadId(id)
        if (id) {
            setEnabled(true)
        }
    }

    return {
        thread,
        selectedThreadId,
        fetchThread,
        ...queryResult
    }
}
