import { FC, useEffect, useState } from 'react'
import { Image, View } from 'react-native'
import { Button, Typography, Tooltip } from '@/components/ui'
import { useColors } from '@/hooks/useColors'
import cn from 'clsx'
import { useColorScheme } from 'nativewind'
import { IThread } from '@/shared/types/threads.interface'
import { useStore } from '@/shared/store'
import { formattedDate } from '@/shared/libs/hooks'

interface IProps {
    thread: IThread[]
}

const Header: FC<IProps> = ({ thread }) => {
    const [activeArrow, setActiveArrow] = useState<'prew' | 'next' | 'all' | ''>('')
    const [isImportant, setIsImportant] = useState(false)

    const { currentThread, digestThreads, setChangeCurrentThread } = useStore()

    const { colorScheme } = useColorScheme()
    const { text, color, bg } = useColors()
    const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'

    const tooltipContent = isImportant
        ? 'Click to teach Scive this conversation is not important'
        : 'Click to teach Scive this conversation is important'

    const checkCurrentConversation = (current: number, count: number) => {
        if (current === 0 && count === 0) return setActiveArrow('')
        if (current === 1 && count === 1) return setActiveArrow('')
        if (current === 1) return setActiveArrow('next')
        if (current === count) return setActiveArrow('prew')

        return setActiveArrow('all')
    }

    useEffect(() => {
        checkCurrentConversation(currentThread, digestThreads.length)
    }, [currentThread, digestThreads])


    const threadMessages = thread[currentThread !== 0 ? currentThread-1 : 0].messages[0]

    return (
        <View className={cn('pt-base-x1 justify-between flex-col border-b overflow-hidden ', deviderColor)}>
            <View
                className={cn('flex-1 flex-row items-center px-base-x4 border-b', deviderColor)}
                style={{ gap: 8 }}>
                <Typography variant='h3' className={cn('flex-grow', text.inactiveText)}>
                    Related Conversations
                </Typography>
                <View className='flex flex-row items-center' style={{ gap: 8 }}>
                    <Typography variant='body' className={cn('px-4 text-xs', text.inactiveText)}>
                        {currentThread} of {digestThreads.length}
                    </Typography>
                    <View className='flex flex-row' style={{ gap: 8 }}>
                        <Tooltip placement={'bottom'} content='Previous'>
                            <Button
                                className='p-1.5 rounded-md'
                                icon='ChevronLeft'
                                styleOnHover={activeArrow.length ? bg.hoverIconSurface : ''}
                                colorIcon={
                                    activeArrow !== 'prew'
                                        ? activeArrow === 'all'
                                            ? color.black
                                            : color.inactiveIcons
                                        : color.black
                                }
                                sizeIcon={24}
                                onPress={activeArrow.length ? () => setChangeCurrentThread('prew') : undefined}
                            />
                        </Tooltip>
                        <Tooltip placement='bottom' content='Next'>
                            <Button
                                className='p-1.5 rounded-md'
                                icon='ChevronRight'
                                styleOnHover={activeArrow.length ? bg.hoverIconSurface : ''}
                                colorIcon={
                                    activeArrow !== 'next'
                                        ? activeArrow === 'all'
                                            ? color.black
                                            : color.inactiveIcons
                                        : color.black
                                }
                                sizeIcon={24}
                                onPress={activeArrow.length ? () => setChangeCurrentThread('next') : undefined}
                            />
                        </Tooltip>
                    </View>
                </View>
            </View>
            <View
                className='px-base-x4 pt-base-x3 pb-base-x2 flex-row justify-between items-center'
                style={{ gap: 16 }}>
                <View className='flex-row items-center' style={{ gap: 16 }}>
                    <Tooltip placement={'top'} content={threadMessages.metadata.from_}>
                        <Typography
                            variant='button-default'
                            className={cn(
                                text.boldText,
                                'bg-important, flex-1 max-w-[250px]  desktop:max-w-[330px] font-bold'
                            )}
                            nowrap
                            numberOfLines={1}
                            ellipsizeMode='tail'>
                            {threadMessages.metadata.from_}
                        </Typography>
                    </Tooltip>
                    <Tooltip placement={'top'} content={threadMessages.metadata.from_}>
                        <Typography
                            variant='calout'
                            className='text-light-text max-w-[166px]'
                            nowrap
                            numberOfLines={1}
                            ellipsizeMode='tail'>
                            {threadMessages.metadata.from_}
                        </Typography>
                    </Tooltip>
                </View>

                <Typography variant='label-date' className={cn(text.black, 'min-w-fit')}>
                    {formattedDate(threadMessages.metadata.created_at as string)}
                </Typography>
            </View>
            <View
                className={cn('px-base-x4 py-0.5 flex-row items-center border-b', deviderColor)}
                style={{ gap: 8 }}>
                <Typography variant='h4' className={cn('font-bold leading-7', text.boldText)}>
                    {threadMessages.metadata.subject}
                </Typography>
                <Tooltip placement='top' content={tooltipContent}>
                    <Button onPress={() => setIsImportant(!isImportant)}>
                        <Image
                            source={require('@/assets/icons/important.svg')}
                            tintColor={isImportant ? '#FDBA74' : colorScheme === 'dark' ? '#374151' : ''}
                            className='w-[15px] h-[14px] m-base-x2'
                        />
                    </Button>
                </Tooltip>
            </View>
        </View>
    )
}

export default Header
