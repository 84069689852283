import { FC, useEffect, useState } from 'react'
import { View } from 'react-native'
import ButtonGenerate from './ButtonGenerate'
import Editor from './editor/Editor'
import Toast from 'react-native-toast-message'
import { useStore } from '@/shared/store'
import { useSendGenerateMessage, useSendMessage } from '@/components/screens/inbox/api'
import { useRoute } from '@react-navigation/native'
import { IComposeType } from '@/shared/store/types'

interface IProps {
    type: IComposeType
}

const Body: FC<IProps> = ({ type }) => {
    const router = useRoute()
    const { sendMessage } = useSendMessage()
    const { sendGenerateMessage } = useSendGenerateMessage()
    const {
        sendMessageForm,
        setSendMessageForm,
        validateField,
        noValidEmail,
        showCompose,
        noValidPrompt,
        clearValidateField,
        thread,
        sendMessageGenerateForm,
        setSendMessageGenerateForm,
        chatType,
        digestThreads,
        currentThread,
    } = useStore()

    useEffect(() => {
        noValidPrompt && clearValidateField('prompt')
        noValidEmail && clearValidateField('email')
    }, [noValidPrompt, noValidEmail, clearValidateField, router, digestThreads, thread])

    const onSubmit = async () => {
        if (showCompose) {
            if (!!sendMessageForm.to && !!sendMessageForm.text) {
                validateField(sendMessageForm.to, 'email')
                let sentMessage = false
                !noValidEmail && (sentMessage = await sendMessage(sendMessageForm))
                sentMessage && Toast.show({
                    type: 'success',
                    text1: 'Reply Box is Ready',
                    text2: 'Your message has been successfully sent.'
                })
            } else {
                validateField('', 'email')
                Toast.show({
                    type: 'error',
                    text1: 'Reply box is empty'
                })
            }
        } else {
            let from = thread?.messages[0].metadata.from_ 
            if (chatType === 'chat_dashboard') {
                from = digestThreads[currentThread !== 0 ? currentThread - 1 : 0].messages[0].metadata.from_
            }
            if (!!from && !!sendMessageForm.text) {
                const sentMessage = await sendMessage(sendMessageForm)
                sentMessage &&
                    Toast.show({
                        type: 'success',
                        text1: 'Reply Box is Ready',
                        text2: 'Your message has been successfully sent.'
                    })
            } else {
                Toast.show({
                    type: 'error',
                    text1: 'Reply box is empty'
                })
            }
        }
    }

    // const onGenerate = () => {
    //     if (!!sendMessageGenerateForm.prompt) {
    //         validateField(sendMessageGenerateForm.prompt, 'prompt')
    //         sendGenerateMessage(sendMessageForm)
    //         Toast.show({
    //             type: 'success',
    //             text1: 'Reply Box is Ready',
    //             text2: 'Generation request sent. Please wait for completion.'
    //         })
    //         return
    //     }
    //     validateField('', 'prompt')
    //     Toast.show({
    //         type: 'error',
    //         text1: 'Please enter the prompt.'
    //     })
    //     return
    // }

    return (
        <View className='flex-col flex-1'>
            <ButtonGenerate
                onGenerate={() => console.log('GENERATE')}
                // onGenerate={onGenerate}
                value={sendMessageGenerateForm.prompt || ''}
                onChange={setSendMessageGenerateForm}
            />
            <Editor
                value={chatType === type ? sendMessageForm.text : ''}
                setValue={setSendMessageForm}
                onSubmit={onSubmit}
                type={type}
            />
        </View>
    )
}

export default Body
