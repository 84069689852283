import { useQuery } from "@tanstack/react-query";
import { ThreadsService } from "@/services/inbox/threads.api";
import { useStore } from "@/shared/store";
import { useEffect, useState } from "react";
import { IThread } from "@/shared/types/threads.interface";


export const useGetThreads = () => {
	const [enabled, setEnabled] = useState<boolean>(false)
	const {
        threads,
        setThreads,
        refetchThreads,
        setRefetchThreads,
    } = useStore((state) => state)
    const queryResult = useQuery(
		['get_threads'],
		() => ThreadsService.getThreads(),
		{
			enabled,
			onSuccess(res) {
				setRefetchThreads(false)
				setThreads((res.data as IThread[]) || [])
			}
		}
	);

	useEffect(() => {
        refetchThreads && fetchThreads()
    }, [threads])

    useEffect(() => {
        enabled && setEnabled(false)
    }, [enabled])

	const fetchThreads = () => setEnabled(true)

    return {
		threads,
		fetchThreads,
        ...queryResult,
    }
}