import { ITodo } from "@/shared/types/todo.interface";
import { TTodoCategories } from "../types";

export const filterTodos = {
    all: (arr: ITodo[]) => arr,
    new: (arr: ITodo[]) => arr.filter(todo => {
        const now = new Date();
        const dateToCheck = new Date(todo.due || '');
        return dateToCheck.toDateString() === now.toDateString()
    }),
    favorite: (arr: ITodo[]) => arr.filter(todo => todo.is_favorite && todo),
    due: (arr: ITodo[]) => arr.sort((a: any, b: any) => new Date(b.due).getTime() - new Date(a.due).getTime()),
}

export const filterByCategory = (arr: ITodo[], category: TTodoCategories | null ): ITodo[] => {
    const todosTab = arr.filter(todo => !todo.parent_id)

    const todosTabFiltered = todosTab.filter(todo => {
        if(category !== 'all') {
            if(category === 'today' || category === 'tomorrow')
                return todo.date && todo.date.toLowerCase() === category
            
            if(category === 'important') return todo.is_favorite
            
            if(category === 'completed') return todo.is_done
        }
        return todo
    })

    return filterTodos['due'](todosTabFiltered)
}

export const filterWithoutCategory = (arr: ITodo[]): ITodo[] => {
    const todosTab = arr.filter(todo => !todo.parent_id)

    const withoutDue = todosTab.filter((todo) => !todo.due)
    const withoutTomorrow = todosTab.filter(todo => todo.date && todo.date.toLowerCase() !== 'tomorrow')
    const withoutDays = withoutTomorrow.filter(todo => todo.date && todo.date.toLowerCase() !== 'today')
    
    return [...filterTodos['due'](withoutDays), ...withoutDue]
}
