import { IThread, IUpdateLabelsData } from '@/shared/types/threads.interface'
import { EThreadLabels } from '../enum'
import { TThreadsCategories } from '../types'

export const checkLabels = (labels: string[]) => ({
    NEW: labels.includes(EThreadLabels.NEW),
    INBOX: labels.includes(EThreadLabels.INBOX),
    SPAM: labels.includes(EThreadLabels.SPAM),
    TRASH: labels.includes(EThreadLabels.TRASH),
    SENT: labels.includes(EThreadLabels.SENT),
    DRAFT: labels.includes(EThreadLabels.DRAFT),
    UNREAD: labels.includes(EThreadLabels.UNREAD),
    IMPORTANT: labels.includes(EThreadLabels.IMPORTANT)
    
})

export const checkUnread = (threads: IThread[]) => {
    // Check Unread Threads Count
    const unread = threads.filter((thread) => thread.messages[0].metadata.labels.includes(EThreadLabels.UNREAD))
    const countInboxUnread = unread.filter((thread) =>
        thread.messages[0].metadata.labels.includes(EThreadLabels.INBOX)
    )
    const countSpamUnread = unread.filter((thread) => thread.messages[0].metadata.labels.includes(EThreadLabels.SPAM))

    return {
        unread,
        counts: {
            inbox: countInboxUnread.length,
            spam: countSpamUnread.length
        }
    }
}
export const filterThreads = (threads: IThread[], category: TThreadsCategories | null) => {
    const updatedThreads = threads.filter((thread) => {
        const labels = thread.messages[0].metadata.labels
        const { INBOX, SENT, SPAM, TRASH, UNREAD} = checkLabels(labels)
        if (UNREAD) { 
            labels.push(EThreadLabels.NEW)
        }
            // if(category === 'new'&& NEW )
        if (category === 'sent' && SENT && INBOX) return
        if (category === 'sent' && SPAM) return
        if (category === 'sent' && TRASH) return
        if (category === 'draft' && SPAM) return
        if (category === 'draft' && TRASH) return
        

        return labels.includes(EThreadLabels[category?.toUpperCase() as EThreadLabels])

    })
    return updatedThreads
    
}

export const updateThread = (threads: IThread[], selectedThreads: IThread[]) =>
    threads.map((thread) => {
        const updatedItem = selectedThreads.find((usThread) => usThread?.id === thread.id)
        return updatedItem ? updatedItem : thread
    })

export const handlerUpdateThreadLabel = (thread: IThread, threadLabels: IUpdateLabelsData, threadId: string, label: string) => {
    const message_ids: string[] = []
    let thread_ids: string[] = []
    let add: string[] = []
    let remove: string[] = []

    const labels = thread.messages[0].metadata.labels
    const { INBOX, SENT, SPAM, TRASH, DRAFT, UNREAD, IMPORTANT } = checkLabels(labels)

    const dublicate = threadLabels.thread_ids.includes(thread.id)
    threadId === thread.id
        ? (thread_ids = dublicate
              ? [...threadLabels.thread_ids]
              : [...threadLabels.thread_ids, thread.id])
        : (thread_ids = [...threadLabels.thread_ids])

    if (label === EThreadLabels.UNREAD || label === 'read' || label === 'unread') {
        const unreadAdd = threadLabels.add.filter((uThread) => !uThread.includes(EThreadLabels.UNREAD))
        const unreadRemove = threadLabels.remove.filter((uThread) => !uThread.includes(EThreadLabels.UNREAD))
        UNREAD
            ? ((remove = [...threadLabels.remove, EThreadLabels.UNREAD]), (add = [...unreadAdd]))
            : (remove = [...threadLabels.remove])
        !UNREAD 
            ? ((add = [...threadLabels.add, EThreadLabels.UNREAD]), (remove = [...unreadRemove]))
            : (add = [...threadLabels.add])
    }

    if (label === EThreadLabels.IMPORTANT || label === 'important' || label === 'not important') {
        const importantAdd = threadLabels.add.filter((uThread) => !uThread.includes(EThreadLabels.IMPORTANT))
        const importantRemove = threadLabels.remove.filter(
            (uThread) => !uThread.includes(EThreadLabels.IMPORTANT)
        )

        IMPORTANT
            ? ((remove = [...threadLabels.remove, EThreadLabels.IMPORTANT]), (add = [...importantAdd]))
            : (remove = [...threadLabels.remove])

        !IMPORTANT
            ? ((add = [...threadLabels.add, EThreadLabels.IMPORTANT]), (remove = [...importantRemove]))
            : (add = [...importantRemove])
    }

    if (
        label === EThreadLabels.SPAM ||
        label === EThreadLabels.TRASH ||
        label === 'spam'
    ) {
        INBOX && (remove = [...threadLabels.remove, EThreadLabels.INBOX])

        label === EThreadLabels.SPAM || label === 'spam' && (add = [...threadLabels.add, EThreadLabels.SPAM])

        if (label === EThreadLabels.TRASH) {
            add = [...threadLabels.add, EThreadLabels.TRASH]
            SPAM && (remove = [...threadLabels.remove, EThreadLabels.SPAM])
        }
    }

    if (label === EThreadLabels.INBOX || label === 'all not spam' || label === 'not spam') {
        if ((!TRASH || !SPAM) && (SENT || DRAFT)) {
            add = [...threadLabels.add, EThreadLabels.INBOX]
        }

        if ((TRASH || SPAM) && (!SENT || !DRAFT)) {
            add = [...threadLabels.add, EThreadLabels.INBOX]

            TRASH && (remove = [...threadLabels.remove, EThreadLabels.TRASH])
            SPAM && (remove = [...threadLabels.remove, EThreadLabels.SPAM])
        }
    }

    return {
        message_ids: message_ids || [],
        thread_ids: thread_ids || [],
        add: add || [],
        remove: remove || []
    }
}
