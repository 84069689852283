import { FC } from 'react'
import { View } from 'react-native'
import { Button, Tooltip, Typography } from '@/components/ui'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'
import { useColorScheme } from 'nativewind'
import { ITodo } from '@/shared/types/todo.interface'

interface IProps {
    todo: ITodo
    updateTask: (id: number, todo: ITodo) => void
}

const TodoItem: FC<IProps> = ({ todo, updateTask }) => {
    const { id, date, is_done, content, is_favorite } = todo && todo

    const isLink = false
    const overdue = date === 'Yesterday'

    const { colorScheme } = useColorScheme()
    const { bg } = useColors()
    const { text: textColor, color } = useColors()

    return (
        <View className='mx-2 py-2 items-center' style={{ gap: 8 }}>
            <View className='flex-row w-full'>
                <View className='flex-row flex-1 items-center'>
                    <Tooltip placement='right' content='Mark completed'>
                        <Button
                            icon={is_done ? 'CheckCircle' : 'Circle'}
                            className='w-[36px] h-[36] justify-center'
                            sizeIcon={16}
                            colorIcon={is_done ? color.tooltip : color.black}
                            onPress={() => {
                                return (
                                    updateTask &&
                                    updateTask(id as number, {
                                        ...todo,
                                        is_done: !is_done
                                    })
                                )
                            }}
                        />
                    </Tooltip>
                    <Typography
                        variant='body-list'
                        className={cn('text-wrap leading-6', textColor.boldText, overdue && 'text-red')}>
                        {content}
                    </Typography>
                </View>
                {isLink && (
                    <Button
                        icon='Link'
                        className='w-[36px] h-[36] justify-center'
                        colorIcon={color.black}
                        sizeIcon={20}
                        onPress={() => console.log('LINK')}
                    />
                )}
                <Button
                    icon='Star'
                    className='w-[36px] h-[36] justify-center'
                    colorIcon={is_favorite ? color.star : color.black}
                    fillIcon={is_favorite ? (colorScheme === 'light' ? '#1F2937' : '#38BDF8') : ''}
                    sizeIcon={20}
                    onPress={() =>
                        updateTask &&
                        updateTask(id as number, {
                            ...todo,
                            is_favorite: !is_favorite
                        })
                    }
                />
            </View>
            {!!todo.due && (
                <View
                    className={cn(
                        'pl-base-x3 pr-base-x3 pt-base-x1 pb-base-x1 rounded-base-x16',
                        bg.inactiveSurface
                    )}
                    // @ts-ignore
                    style={{ width: 'fit-content' }}>
                    <Typography variant='button-plain' className={cn('text-center text-light-text')}>
                        {date}
                    </Typography>
                </View>
            )}
        </View>
    )
}

export default TodoItem
