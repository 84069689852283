import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { DashboardService } from '@/services/dashboard'
import { IGenerateDigestItemResult } from '@/shared/types/dashboard.interface'
import { useStore } from '@/shared/store'
import { useApiAsyncTasks } from './useApiAsyncTasks.api'

export const useApiGetDigestItem = () => {
    // const { setLoadingAsyncTask, digestItem, setDigestItem, setAsyncTaskId } = useStore()
    // const { fetchApiAsyncTasks } = useApiAsyncTasks()


    const [digestItemId, setDigestItemId] = useState<number | null>(null)
    const [enabled, setEnabled] = useState<boolean>(false)

    const queryResult = useQuery(
        ['get_digest_item'],
        () => DashboardService.getDigestItem(digestItemId!),
        {
            enabled,
            async onSuccess(res) {
                console.log('get_digest_item', res?.data)
                //  if (res?.data && res.data.id) {
                //      setAsyncTaskId(res.data.id)
                //      await fetchApiAsyncTasks()
                //      setLoadingAsyncTask(true)
                //  }
            }
        }
    )

    // useEffect(() => {
    //     digestItem?.digest_item_id && setEnabled(true)
    // }, [enabled, digestItem])

    useEffect(() => {
        if (enabled) {
            setEnabled(false)
            // setDigestItem(null)
        }

    }, [enabled])

    const fetchDigestItem = (digest_item: IGenerateDigestItemResult) => {
        setDigestItemId(digest_item.digest_item_id)
        setEnabled(true)
    }

    return {
        fetchDigestItem,
        ...queryResult
    }
}
