import { getLlmAsyncTasksUrl, getLlmUrl, getMessagesUrl } from '@/config/api.config'
import { request } from '../api/request.api'
import { headers } from '../api/headers'
import {
    IDigestResponse,
    IGetSummaryExtraResponse,
    IIGenerateDigestResponse,
    ITodoSuggestionsResponse
} from '@/shared/types/dashboard.interface'

export const DashboardService = {
    async getGenerateDigests() {
        return request<IIGenerateDigestResponse>({
            url: getLlmUrl(`/digests/generate-digest`),
            method: 'POST',
            headers: await headers()
        })
    },
    async getGenerateDigestItem(digest_item_id: number | null) {
        if (!digest_item_id) return

        return request<IIGenerateDigestResponse>({
            url: getLlmUrl(`/digests/generate-digest-item-details`),
            method: 'POST',
            data: { digest_item_id },
            headers: await headers()
        })
    },

    async getAsyncTask(async_task_id: number) {
        return request<IIGenerateDigestResponse>({
            url: getLlmAsyncTasksUrl(`/${async_task_id}`),
            method: 'GET',
            headers: await headers()
        })
    },

    async getDigestList() {
        return request<IDigestResponse>({
            url: getLlmUrl(`/digests`),
            method: 'GET',
            headers: await headers()
        })
    },

    async getDigestItems() {
        return request<IDigestResponse>({
            url: getLlmUrl(`/digest-items`),
            method: 'GET',
            headers: await headers()
        })
    },
    async getDigestItem(digest_item_id: number) {
        return request<IDigestResponse>({
            url: getLlmUrl(`/digest-items/${digest_item_id}`),
            method: 'GET',
            headers: await headers()
        })
    },

    async getDigestItemDetails() {
        return request<IDigestResponse>({
            url: getLlmUrl(`/digest-item-details`),
            method: 'GET',
            headers: await headers()
        })
    },
    async getHighlights() {
        return request<IDigestResponse>({
            url: getLlmUrl(`/highlights`),
            method: 'GET',
            headers: await headers()
        })
    },
    async getTodoSuggestions() {
        return request<ITodoSuggestionsResponse>({
            url: getLlmUrl(`/todo-suggestions`),
            method: 'GET',
            headers: await headers()
        })
    },

    //  !OLD
    async getSummaryExtra(msg_id: string | null) {
        if (msg_id === undefined || msg_id === null) return

        return request<IGetSummaryExtraResponse>({
            url: getMessagesUrl(`/summary_extra?esid=${msg_id}`),
            method: 'GET',
            headers: await headers()
        })
    }
}
