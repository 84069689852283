import { FC, ReactNode, useEffect, useState } from 'react'
import { useTypedRoute } from '@/hooks/useTypedRoute'
import { ComposePopup, MailHeader, MailList, TableList } from './components'
import { View } from 'react-native'
import { useMailPosition } from '@/context/MailPositionContext'
import { Navbar } from '@/components/ui'
import cn from 'clsx'
import { useColorScheme } from 'nativewind'
import { useGetThread, useGetThreads } from './api'
import { useStore } from '@/shared/store'

const Inbox: FC = () => {

	const { position } = useMailPosition()
	const { params } = useTypedRoute<'Inbox'>()
	const [sections, setSections] = useState<ReactNode>([])
	
	const { isLoading, fetchThreads, } = useGetThreads()
	const { isLoading: isLoadingThread, selectedThreadId } = useGetThread()
	const { colorScheme } = useColorScheme()
	const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'

	const {
        countInboxUnread,
        countSpamUnread,
        filteredThreads,
        foundThreads,
        searchThreadValue,
        setChatType,
        chatType,
        clearDigestThreads,
    } = useStore()

	useEffect(() => { 
        if(chatType === 'chat_dashboard') {
            setChatType(null)
            clearDigestThreads()
        }
        if (params?.path === undefined) {
            setSections(
                <TableList
                    threads={!!foundThreads.length || !!searchThreadValue.length ? foundThreads : filteredThreads}
                    isLoading={isLoading} category={'inbox'}                />
            )
        } else {
            setSections(
                <TableList
                    threads={!!foundThreads.length || !!searchThreadValue.length ? foundThreads : filteredThreads}
                    isLoading={isLoading} category={'inbox'}                />
            )
        }
    }, [params?.path, filteredThreads, foundThreads, searchThreadValue])
	
	


    const navbar = [
        { icon: 'BellDot', title: 'New', path: 'new', countUnread: 12 },
        { icon: 'Mail', title: 'Inbox', path: undefined, countUnread: countInboxUnread },
        { icon: 'Send', title: 'Sent', path: 'sent' },
        { icon: 'File', title: 'Drafts', path: 'drafts' },
        {
            icon: 'AlertOctagon',
            title: 'Spam',
            path: 'spam',
            countUnread: countSpamUnread,
            isRedCounter: true
        },
        { icon: 'Trash2', title: 'Trash', path: 'trash' }
    ]
    return (
        <View className='flex-row flex-1' style={{ gap: 4 }}>
            {position === 'horizontal' && !isLoadingThread && !!selectedThreadId ? null : (
                <Navbar navbar={navbar} />
            )}
            <View
                className={cn(
                    'flex-1 relative z-20 px-base-x1 pt-base-x3 pb-base-x6 border-l-[1px]',
                    deviderColor
                )}>
                <MailHeader />
                <MailList
                    sections={sections}
                    isLoading={!isLoadingThread && !!selectedThreadId}
                />
                <ComposePopup type='compose' />
            </View>
        </View>
    )
}

export default Inbox
