import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { DashboardService } from '@/services/dashboard'

export const useApiGetDigestItems = () => {
    const [enabled, setEnabled] = useState<boolean>(false)

    const queryResult = useQuery(['get_digest_items'], () => DashboardService.getDigestItems(), {
        enabled,
        async onSuccess(res) {
            console.log('get_digest_items', res?.data)
            //  if (res?.data && res.data.id) {
            //      setAsyncTaskId(res.data.id)
            //      await fetchApiAsyncTasks()
            //      setLoadingAsyncTask(true)
            //  }
        }
    })

    useEffect(() => {
        enabled && setEnabled(false)
    }, [enabled])

    const fetchDigestItems = () => {
        setEnabled(true)
    }

    return {
        fetchDigestItems,
        ...queryResult
    }
}
