import { FC, useEffect, useRef } from 'react'
import { View } from 'react-native'
import { Pressable } from 'react-native-web-hover'
import cn from 'clsx'
import { Icon, Typography } from '@/components/ui'
import { IDropdownProps } from './dropdown.interface'
import { useColors } from '@/hooks/useColors'
import { Tooltip } from '@/components/ui'

const Dropdown: FC<IDropdownProps> = ({
    isVisible,
    button,
    options,
    left,
    variant,
    classNameText,
    setValue,
    onClose
}) => {
    const containerRef = useRef(null)
    const { bg, text, color } = useColors()
    const handleValue = (value: string) => {
        setValue(value)
        onClose()
    }

    useEffect(() => {
        window.document.addEventListener('click', onClickDocument)
    }, [])

    const onClickDocument = (event: Event) => {
        if (event.target != containerRef.current) {
            onClose()
        }
    }

    return (
        <View className='relative z-20' ref={containerRef}>
            {button}
            {isVisible && (
                <View
                    className={cn(
                        'absolute z-50 top-[37px] rounded-base-x2 right-0 pt-base-x2 pb-base-x2 flex-col w-[150px] shadow',
                        bg.white
                    )}
                    style={{
                        left: left
                    }}>
                    {options?.map((item) => (
                        <Pressable
                            key={item.text}
                            //@ts-ignore
                            onPress={() => handleValue(item.text)}>
                            {({ hovered }) => (
                                <View
                                    className={cn(
                                        'pt-base-x2 pb-base-x2 pr-base-x4 pl-base-x4  items-start',
                                        hovered && bg.hoverIconSurface
                                    )}>
                                    <View
                                        className='flex-row items-center justify-between'
                                        style={{ gap: 16 }}>
                                        {!!item?.icon &&
                                            (typeof item.icon === 'string' ? (
                                                <Icon
                                                    name={item.icon}
                                                    size={20}
                                                    color={color.black}
                                                    className={cn(item.reverseIcon && 'scale-x-[-1]')}
                                                />
                                            ) : (
                                                item.icon
                                            ))}
                                        <Typography
                                            variant={variant || 'subheadline'}
                                            className={cn(text.black, classNameText)}>
                                            {item.text}
                                        </Typography>
                                    </View>
                                </View>
                            )}
                        </Pressable>
                    ))}
                </View>
            )}
        </View>
    )
}

export default Dropdown
