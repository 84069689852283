import cn from 'clsx'
import { FC } from 'react'
import { View } from 'react-native'
import Header from './components/Header'
import Content from '@/components/screens/dashboard/components/mailChat/components/Content'
import { useColorScheme } from 'nativewind'
import { useStore } from '@/shared/store'
import { Typography } from '@/components/ui'
import { IThread } from '@/shared/types/threads.interface'

const MailChat: FC = () => {
    const { colorScheme } = useColorScheme()
    const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'
    
    const { digestThreads, chatType } = useStore()

    return (
        <View className={cn('pt-base-x3 pb-base-x2 flex-1 justify-between border-b-[1px] ', deviderColor)}>
            {!!digestThreads.length && chatType === 'chat_dashboard' ? (
                <View className={cn('pb-base-x2 relative rounded-base-x4 flex-1 justify-between')}>
                    <Header thread={digestThreads as IThread[]} />
                    <Content />
                </View>
            ) : (
                <Typography variant='body' className='text-center text-gray-400 pt-base-x9 '>
                    No digest selected
                </Typography>
            )}
        </View>
    )
}

export default MailChat
