import { FC } from 'react'
import { View } from 'react-native'
import { Button, Typography } from '@/components/ui'
import { StyledProps } from 'nativewind'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'
import { Mail } from 'lucide-react-native'
import { TCounter } from '@/shared/types/dashboard.interface'
import { TSummaryState } from '@/shared/store/types'

interface IProps {
    title: string
    subtitle?: string
    className?: StyledProps<string>
    counter?: TCounter
    status?: TSummaryState
}
const Header: FC<IProps> = ({ title, subtitle, counter, className, status = 'default' }) => {
    const { bg, text } = useColors()
    return (
        <View
            className={cn(
                'pl-base-x4 pr-base-x4 flex-row items-center',
                {
                    'justify-between': counter?.subject === 'new'
                },
                className
            )}>
            <View className='flex-col'>
                <Typography variant='h3' className={cn(text.inactiveText)}>
                    {title}
                </Typography>
                <Typography variant='subheadline' className='text-light-text'>
                    {subtitle}
                </Typography>
            </View>
            {counter && (
                <View className='flex flex-row' style={{ gap: 16 }}>
                    <View
                        className={cn(
                            'pl-base-x3 pr-base-x3 pt-base-x1 pb-base-x1 justify-center items-center ',
                            counter.subject === 'new' && counter.count === 0 && bg.white,
                            counter.subject === 'left' && counter.count === 0 && bg.white
                        )}
                        // @ts-ignore
                        style={{ userSelect: 'none' }}>
                        <Typography
                            variant='button-plain'
                            className={cn(
                                'flex text-center text-primary',
                                counter.subject === 'left' && text.black,
                                counter.count === 0 && counter.subject === 'new' && 'text-sky-200',
                                counter.subject === 'left' && counter.count === 0 && 'text-error'
                            )}>
                            {counter.count}{' '}
                            {counter.subject === 'left' ? (
                                counter.subject
                            ) : (
                                <Mail
                                    size={20}
                                    className={cn('ml-[6px] stroke-primary', {
                                        'stroke-sky-200': counter.count === 0 && counter.subject === 'new'
                                    })}
                                />
                            )}
                        </Typography>
                    </View>
                </View>
            )}
        </View>
    )
}

export default Header
