import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { DashboardService } from '@/services/dashboard'

export const useApiGetTodoSuggestions = () => {

    const [enabled, setEnabled] = useState<boolean>(false)

    const queryResult = useQuery(
        ['get_todo_suggestions',],
        () => DashboardService.getTodoSuggestions(),
        {
            enabled,
            async onSuccess(res) {
                console.log('get_todo_suggestions', res?.data)
                //  if (res?.data && res.data.id) {
                //      setAsyncTaskId(res.data.id)
                //      await fetchApiAsyncTasks()
                //      setLoadingAsyncTask(true)
                //  }
            }
        }
    )

    useEffect(() => {
        enabled && setEnabled(false)
    }, [enabled])

    const fetchTodoSuggestions = () => {
        setEnabled(true)
    }

    return {
        fetchTodoSuggestions,
        ...queryResult
    }
}
