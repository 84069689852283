import { DashboardService } from '@/services/dashboard'
import { useStore } from '@/shared/store'
import { IDigestData } from '@/shared/types/dashboard.interface'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useGetThreads } from '../../inbox/api'

export const useApiGetDigestList = () => {
    const [enabled, setEnabled] = useState<boolean>(false)

    const { digests, setDigests, setFetchDigests, fetchDigests } = useStore()
    const { fetchThreads, isLoading } = useGetThreads() // !TEST

    
    const queryResult = useQuery(['get_llm_digest_list'], () => DashboardService.getDigestList(), {
        enabled,
        async onSuccess(res) {
            console.log('get_llm_digest_list', res, !isLoading)
            await fetchThreads()
            if (res && res.data) {
                setDigests(res.data as IDigestData[])
                setFetchDigests(false)
            }
        }
    })
    
    useEffect(() => {
        if (!digests.length || fetchDigests) {
            fetchApiDigestList()
        }
    }, [digests, fetchDigests])
    // }, [digests, fetchDigests, isLoading])

    useEffect(() => {
        if (enabled) {
            setEnabled(false)
        }
    }, [enabled])

    const fetchApiDigestList = () => {
        setEnabled(true)
    }

    return {
        digests,
        setEnabled,
        fetchApiDigestList,
        ...queryResult
    }
}
