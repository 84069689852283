import { FC } from 'react'
import { View } from 'react-native'
import { Typography } from '@/components/ui'
import Header from './components/Header'
import Content from './components/Content'
import { TPath } from '../../inbox.interface'
import cn from 'clsx'
import { useStore } from '@/shared/store'
import { IThread } from '@/shared/types/threads.interface'

interface IProps {
    path: TPath
}

const MailChat: FC<IProps> = ({ path }) => {
    const { thread, chatType } = useStore((state) => state)

    return (
        <View className={cn('flex-1')}>
            {!!thread && chatType === 'chat_inbox' ? (
                <View className={cn('pb-base-x2 relative rounded-base-x4 flex-1 justify-between')}>
                    <Header thread={thread as IThread} />
                    <Content path={path} />
                </View>
            ) : (
                <Typography variant='body' className='text-center text-gray-400 pt-base-x9 '>
                    No conversations selected
                </Typography>
            )}
        </View>
    )
}

export default MailChat
