import { FC, useState } from 'react'
import { View } from 'react-native'
import { Button, Compose, Input, Typography } from '@/components/ui'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'
import { useStore } from '@/shared/store'
import { IComposeType } from '@/shared/store/types'

interface IProps {
    type: IComposeType
}

const ComposePopup: FC<IProps> = ({ type }) => {
    const { bg, text, color, border } = useColors()
    const [maxHeight, setMaxHeight] = useState(false)
    const [minSize, setMinSize] = useState(false)

    const { sendMessageForm, setSendMessageForm, noValidEmail, showCompose, setShowCompose, setChatType } =
        useStore((state) => state)

    const toggleMaxHeight = () => {
        setMaxHeight(!maxHeight)
        setMinSize(false)
    }

    const toggleMinSize = () => {
        setMinSize(!minSize)
        if (maxHeight) {
            setMaxHeight(false)
        }
    }
    const handleClose = () => {
        setMaxHeight(false)
        setMinSize(false)
        setShowCompose()
        setChatType(null)
    }

    return (
        <>
            <View
                className={cn(
                    'absolute right-base-x2 flex-col bottom-base-x2 max-w-[675px] w-fit rounded-base-x4 overflow-hidden z-50 shadow-xl bg-white',
                    !showCompose && 'hidden',
                    maxHeight && 'top-base-x2 bottom-base-x2',
                    !minSize && 'w-full'
                )}>
                <View
                    className={cn(
                        'min-w-[345px] flex-row justify-between items-center pr-base-x4 pl-base-x4 pt-base-x2 pb-base-x2',
                        bg.inactiveIcons
                    )}>
                    <Typography variant='subheadline' className={text.white}>
                        New message
                    </Typography>

                    <View className='flex-row' style={{ gap: 8 }}>
                        <Button
                            icon={minSize ? 'MoveUpLeft' : 'MoveDownRight'}
                            sizeIcon={24}
                            styleOnHover={bg.hoverOverHoverSurface}
                            colorIcon={color.black}
                            className='p-base-x1'
                            onPress={toggleMinSize}
                        />
                        <Button
                            icon={maxHeight ? 'MoveDown' : 'MoveUp'}
                            colorIcon={color.black}
                            styleOnHover={bg.hoverOverHoverSurface}
                            sizeIcon={24}
                            className='p-base-x1'
                            onPress={toggleMaxHeight}
                        />
                        <Button
                            icon='X'
                            colorIcon={color.black}
                            sizeIcon={24}
                            className='p-base-x1'
                            styleOnHover={bg.hoverOverHoverSurface}
                            onPress={handleClose}
                        />
                    </View>
                </View>
                {!minSize && (
                    <>
                        <View className={cn('border-b', border.black)}>
                            <Input
                                placeholder='To:'
                                onChangeText={(value) => setSendMessageForm('to', value, type)}
                                value={sendMessageForm.to}
                                className={cn(
                                    'text-base-body2 h-[36px] w-full outline-none pl-base-x2 pr-base-x2 border-b',
                                    text.disabledText,
                                    border.divider,
                                    noValidEmail
                                        ? 'border border-error bg-error/20 text-error'
                                        : (text.disabledText, bg.white, sendMessageForm.to && text.boldText)
                                )}
                            />
                            <Input
                                placeholder='Subject:'
                                onChangeText={(value) => setSendMessageForm('subject', value, type)}
                                value={sendMessageForm.subject}
                                className={cn(
                                    'text-gray-300 text-base-body2 h-[36px] w-full outline-none pl-base-x2 pr-base-x2',
                                    text.disabledText,
                                    bg.white,
                                    sendMessageForm.subject && text.boldText
                                )}
                            />
                        </View>
                        <Compose className='min-h-[300px] flex-1' onlyMax type={type} />
                    </>
                )}
            </View>
        </>
    )
}

export default ComposePopup
