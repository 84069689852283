import { FC } from 'react'
import { ScrollView } from 'react-native'
import { TableRow } from '../index'
import cn from 'clsx'
import { Loader } from '@/components/ui'
import { TThreadsCategories } from '@/shared/store/types'
import { useGetThread, useUpdateThreadLabels } from '../../api'
import { useStore } from '@/shared/store'
import { EThreadLabels } from '@/shared/store/enum'
import { IThread } from '@/shared/types/threads.interface'

interface IProps {
    threads: IThread[]
    isLoading: boolean
    category: TThreadsCategories
}

const TableList: FC<IProps> = ({ threads, isLoading }) => {
    const { fetchThread } = useGetThread()
    const { setSelectedThreads, selectedThreads, removeThread } = useStore((state) => state)
	const { updateThreadLabels } = useStore((state) => state)
    const { fetchUpdateThreadLabels } = useUpdateThreadLabels()

    const handleUpdateThread = (threadId: string, label: string) => {
        updateThreadLabels(label, threadId)
        fetchUpdateThreadLabels(label as EThreadLabels, threadId)
    }
    const handleRemoveThread = (threadId: string) => {
        // !API
        removeThread(threadId)
    }

	return (
        <ScrollView className={cn('flex-col flex-1 ')}>
            {!isLoading ? (
                threads.length ? (
                    threads.map((item) => {
                        return (
                            <TableRow
                                draft={false}
                                key={item.id}
                                id={item.id}
                                thread={item}
                                isSelected={selectedThreads.includes(item.id)}
                                setSelectedThreads={() => setSelectedThreads(item.id)}
                                selectThread={() => fetchThread(item.id)}
                                updateThread={handleUpdateThread}
                                removeThread={() => handleRemoveThread(item.id)}
                            />
                        )
                    })
                ) : (
                    <Loader text='Loading threads' />
                )
            ) : (
                <Loader text='Loading threads' />
            )}
        </ScrollView>
    )
}

export default TableList
