import { FC, useEffect, useState } from 'react'
import { Pressable } from 'react-native'
import Header from './components/Header'
import Body from './components/Body'
import cn from 'clsx'
import { StyledProps } from 'nativewind'
import { useRoute } from '@react-navigation/native'
import { TSize } from './compose.interface'

interface IProps {
    className?: StyledProps<string>
    onlyMax?: boolean
    type: 'compose' | 'chat_dashboard' | 'chat_inbox'
}

const Compose: FC<IProps> = ({ className, onlyMax, type }) => {
    const [size, setSize] = useState<TSize>(null)
    const route = useRoute()
    const currentPage = route.name

    useEffect(() => {
        if (currentPage === 'Inbox') {
            setSize('min')
        } else {
            setSize('max')
        }

        if (onlyMax) {
            setSize('max')
        }
    }, [])

    return (
        <Pressable
            className={cn('p-base-x4 flex-auto justify-between', size === 'max' ? className : 'flex-initial')}
            onPress={() => setSize(size === 'min' ? 'max' : 'max')}
            // @ts-ignore
            style={{ gap: 8, cursor: size === 'max' ? 'default' : 'pointer' }}>
            <Header size={size} setSize={setSize} onlyMax={onlyMax} />
            {size === 'max' && <Body type={type} />}
        </Pressable>
    )
}

export default Compose
