import { FC, useEffect } from 'react'
import { View } from 'react-native'
import { useTypedRoute } from '@/hooks/useTypedRoute'
import { Navbar } from '@/components/ui'
import { Account, Billing, Plan } from './components'
import { useStore } from '@/shared/store'

const Settings: FC = () => {
	const { params } = useTypedRoute<'Settings'>()
	
	const navbar = [
		{ icon: 'User2', title: 'Account', path: undefined },
		{ icon: 'Trophy', title: 'Plan', path: 'plan' },
		{ icon: 'Banknote', title: 'Billing', path: 'billing' },
		{
			icon: 'Bell',
			title: 'Notifications',
			path: 'notifications',
			disabled: true
		}
	]
	const { clearThread, clearDigestThreads } = useStore()

	useEffect(() => {
        clearThread()
		clearDigestThreads()
    }, [])

	return (
		<View className='flex-row flex-1' style={{ gap: 4 }}>
			<Navbar navbar={navbar} title='Settings' />
			{params?.path === undefined && <Account />}
			{params?.path === 'plan' && <Plan />}
			{params?.path === 'billing' && <Billing />}
		</View>
	)
}

export default Settings
