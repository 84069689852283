import { FC, useEffect, useState } from 'react'
import { View } from 'react-native'
import { IMessages } from './messages.interface'
import MessageBubble from '@/components/ui/messages/MessageBubble'
import cn from 'clsx'
import { useSizeSelector } from '@/context/SizeSelectorContext'
import { Typography } from '@/components/ui'
import { useColors } from '@/hooks/useColors'
import { useStore } from '@/shared/store'
import { useUserProfile } from '@/components/screens/settings/api'

const Messages: FC<IMessages> = ({ messagesType }) => {
    const { thread, digestThreads, currentThread, chatType } = useStore()

    const { size } = useSizeSelector()
    const { text } = useColors()

    const [me, setMe] = useState<string>('')
    const { userProfile } = useUserProfile()

    useEffect(() => {
        setMe(userProfile?.email || '')
    }, [userProfile, messagesType, chatType])

    const digestIndex = currentThread !== 0 ? currentThread - 1 : 0

    return (
        <View
            className={cn(
                'flex-col mr-base-x2 max-w-[600px]',
                size === 'small' && 'max-w-full',
                size === 'large' && 'm-auto'
            )}
            style={{ gap: 8 }}>
            {messagesType === 'digests' &&
                !!digestThreads.length &&
                digestThreads[digestIndex].messages &&
                digestThreads[digestIndex].messages.map((message: any, index: number) => {
                    const isSameSenderAsPrevious =
                        index > 0 && message.metadata.from_ === digestThreads[digestIndex].messages[index - 1].metadata.from_
                    const isSameSenderAsNext =
                        index < digestThreads[digestIndex].messages.length - 1 &&
                        message.from_ === digestThreads[digestIndex].messages[index + 1].metadata.from_
                    return (
                        <View key={index}>
                            <Typography
                                variant='h4'
                                className={cn(
                                    'font-bold text-black-700 text-center mt-base-x2 mb-base-x4',
                                    text.boldText
                                )}>
                                {index + 1} September
                            </Typography>

                            <MessageBubble
                                message={message}
                                showAvatar={!isSameSenderAsPrevious}
                                showDate={!isSameSenderAsNext}
                                iAmSender={message.metadata.from_.includes(me)}
                            />
                        </View>
                    )
                })}
            {messagesType === 'threads' &&
                !!thread &&
                thread.messages &&
                thread.messages.map((message: any, index: number) => {
                    const isSameSenderAsPrevious =
                        index > 0 && message.metadata.from_ === thread.messages[index - 1].metadata.from_
                    const isSameSenderAsNext =
                        index < thread.messages.length - 1 &&
                        message.metadata.from_ === thread.messages[index + 1].metadata.from_

                    return (
                        <View key={index}>
                            <Typography
                                variant='h4'
                                className={cn(
                                    'font-bold text-black-700 text-center mt-base-x2 mb-base-x4',
                                    text.boldText
                                )}>
                                {index + 1} September
                            </Typography>

                            <MessageBubble
                                message={message}
                                showAvatar={!isSameSenderAsPrevious}
                                showDate={!isSameSenderAsNext}
                                iAmSender={message.metadata.from_.includes(me)}
                            />
                        </View>
                    )
                })}
        </View>
    )
}

export default Messages
