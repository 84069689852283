import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { DashboardService } from "@/services/dashboard";

import { useStore } from "@/shared/store";


export const useGetSummaryExtra = () => {
    const [messageId, setMessageId] = useState<string | null>(null)
    const [enabled, setEnabled] = useState<boolean>(false)

    const { setSummaryExtra } = useStore((state) => state)

    // !OLD
    const queryResult = useQuery(
        ['get_summary_extra'],
        () => () => {},
        // () => DashboardService.getSummaryExtra(messageId),
        {
            enabled
            // onSuccess(res) {
            //     res && setSummaryExtra(res)
            // }
        }
    )

    useEffect(() => {
        if (enabled) {
            setEnabled(false)
        }
    }, [enabled])

    const fetchSummaryExtra = (msgId: string | null) => {
        setMessageId(msgId)
        setEnabled(true)
    }

    return {
        fetchSummaryExtra,
        messageId,
        setMessageId,
        ...queryResult
    }
}