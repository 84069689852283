import { FC } from 'react'
import { TextInput, View } from 'react-native'
import cn from 'clsx'
import { Button, Tooltip } from '@/components/ui'
import { useColors } from '@/hooks/useColors'
import { IMessageFormGenerateName, } from '@/shared/store/types'
import { useStore } from '@/shared/store'

interface IButtonGenerate {
    onGenerate: () => void
    value: string
    onChange: (name: IMessageFormGenerateName, value: string | number) => void
}
const ButtonGenerate: FC<IButtonGenerate> = ({
	onGenerate,
	value,
	onChange,
}) => {
	const { color, border, text } = useColors()
	const { noValidPrompt } = useStore((state) => state)

	return (
        <View className={cn('w-full flex-row items-center border-b mb-base-x1 pb-base-x1', border.divider)}>
            <TextInput
                autoCapitalize={'none'}
                onChangeText={(value) => onChange('prompt', value)}
                autoFocus={true}
                value={value || ''}
                underlineColorAndroid='transparent'
                placeholder='Ask AI to write answer...'
                className={cn(
                    'text-base-body h-[36px] w-full',
                    text.disabledText,
                    noValidPrompt ? 'px-4 border border-error bg-error/20 text-error' : value && text.boldText
                )}
                style={{
                    // @ts-ignore
                    outline: 'none'
                }}
            />
            <Tooltip placement='top' content='Generate'>
                <Button
                    icon='Wand2'
                    sizeIcon={28}
                    onPress={onGenerate}
                    colorIcon={color.black}
                    styleColorIconOnHover='sky'
                    className='p-base-x2 rounded-base-x3'
                />
            </Tooltip>
        </View>
    )
}

export default ButtonGenerate
