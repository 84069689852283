import { FC, useEffect, useState } from 'react'
import { Image, View } from 'react-native'
import { Button, Dropdown, Typography } from '@/components/ui'
import cn from 'clsx'
import { useMailPosition } from '@/context/MailPositionContext'
import { useColors } from '@/hooks/useColors'
import { useColorScheme } from 'nativewind'
import { Tooltip } from '@/components/ui'
import { useStore } from '@/shared/store'
import { EThreadLabels } from '@/shared/store/enum'
import { useTextLayoutHandlers } from '@/utils/replaceText'
import { formattedDate } from '@/shared/libs/hooks'
import { useUpdateThreadLabels } from '../../../api'
import { IThread } from '@/shared/types/threads.interface'

interface IProps {
    thread: IThread
}
interface IActionsValue {
    'report spam': EThreadLabels
    'mark as read': EThreadLabels
    delete: string
}

const Header: FC<IProps> = ({ thread }) => {
    const { position } = useMailPosition()
    const { colorScheme } = useColorScheme()
    const { text, color, border, bg } = useColors()
    const [dropdownVisible, setDropdownVisible] = useState(false)

    const [_, setIsTextOverflowed] = useState(false)
    const { handleContainerLayout, handleTextLayout } = useTextLayoutHandlers(setIsTextOverflowed)

    const { removeThread, updateThreadLabels } = useStore((state) => state)
    const { fetchUpdateThreadLabels } = useUpdateThreadLabels()

    const handleUpdateThread = async (threadId: string, label: string) => {
        await updateThreadLabels(label, threadId)
        await fetchUpdateThreadLabels(label as EThreadLabels, threadId)
    }

    const handleMenuChat = (value: keyof IActionsValue | string) => {
        const actionsValue: IActionsValue = {
            'report spam': EThreadLabels.SPAM,
            'mark as read': EThreadLabels.UNREAD,
            delete: 'delete'
        }
        if (thread) {
            if (actionsValue[value.toLowerCase() as keyof IActionsValue] !== 'delete') {
                handleUpdateThread(thread.id, actionsValue[value.toLowerCase() as keyof IActionsValue])
            } else {
                removeThread(thread.id)
            }
        } 
    }

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible)
    }
    const closeDropdown = () => {
        setDropdownVisible(false)
    }

    const message = thread.messages[0].metadata
    const isImportant = thread ? message.labels.includes(EThreadLabels.IMPORTANT) : false
    const subject = thread ? message.subject : ''
    const from_ = thread ? message.from_ : ''
    const created_at = thread ? !message.created_at : ''

    const tooltipContent = isImportant
        ? 'Click to teach Scive this conversation is not important'
        : 'Click to teach Scive this conversation is important'

    return (
        <View
            className={cn(
                'pl-base-x4 pr-base-x2 pt-base-x1 relative z-50 pb-base-x1 items-center justify-between flex-row border-b',
                border.dividerLight
            )}>
            <View className='flex-col justify-between flex-grow'>
                <View
                    className='flex-row items-center w-full'
                    onLayout={handleContainerLayout}
                    style={{ gap: 8, overflow: 'hidden' }}>
                    <Typography
                        variant='h3'
                        numberOfLines={1}
                        className={cn('max-w-[400px] desktop:max-w-[460px]', text.boldText)}
                        onLayout={handleTextLayout}
                        // @ts-ignore
                        style={{
                            overflow: 'hidden',
                            width: 'fit-content'
                        }}
                        ellipsizeMode='tail'
                        nowrap={true}>
                        {subject}
                    </Typography>

                    <Tooltip placement='icon-important' content={tooltipContent}>
                        <Button
                            className='w-base-x9 h-base-x9 rounded-base-x16 flex items-center justify-center'
                            styleOnHover={bg.hoverIconSurface}
                            onPress={() => handleUpdateThread(thread.id as string, EThreadLabels.IMPORTANT)}>
                            <Image
                                source={require('@/assets/icons/important.svg')}
                                tintColor={isImportant ? '#FDBA74' : colorScheme === 'dark' ? '#374151' : ''}
                                className='h-[14px] w-[15px]'
                            />
                        </Button>
                    </Tooltip>
                </View>
                {position === 'horizontal' && (
                    <View className='pt-base-x2 pb-base-x2 flex-row' style={{ gap: 16 }}>
                        <Typography variant='button-default' className={text.boldText}>
                            {from_}
                        </Typography>
                        <Typography variant='calout' className='text-light-text'>
                            {from_}
                        </Typography>
                    </View>
                )}
            </View>
            <View className='flex-col'>
                <View className='flex-row items-center' style={{ gap: 16 }}>
                    <Dropdown
                        isVisible={dropdownVisible}
                        setValue={handleMenuChat}
                        onClose={closeDropdown}
                        options={[
                            { icon: 'Reply', text: 'Forward', reverseIcon: true },
                            { icon: 'ReplyAll', text: 'Forward all', reverseIcon: true },
                            { icon: 'AlertOctagon', text: 'Report spam' },
                            { icon: 'MailOpen', text: 'Mark as read' },
                            { icon: 'Trash2', text: 'Delete' }
                        ]}
                        button={
                            <Tooltip placement='left' content='More option'>
                                <Button
                                    onPress={toggleDropdown}
                                    icon='MoreVertical'
                                    className='p-base-x2 rounded-base-x16 relative'
                                    styleOnHover={bg.hoverIconSurface}
                                    colorIcon={color.black}
                                />
                            </Tooltip>
                        }
                    />
                </View>
                {position === 'horizontal' && (
                    <Typography variant='label-date' className='pt-base-x2 pb-base-x2 text-right '>
                        {formattedDate(created_at as string)}
                    </Typography>
                )}
            </View>
        </View>
    )
}

export default Header
