import { create } from 'zustand'
import { threadsSlice, todosSlice, summarySlice, llmDashboardSlice } from './slices'
import { TBoundSlices } from './types'

export const useStore = create<TBoundSlices>()((...args) => ({
    ...todosSlice(...args),
    ...threadsSlice(...args),
    ...summarySlice(...args),
    ...llmDashboardSlice(...args),
}))
