import { useQuery } from "@tanstack/react-query"
import { TodosService } from "@/services/togos";
import { useEffect, useState } from "react";
import { useStore } from "@/shared/store";

export const useRemoveTodo = () => {
	const [enabled, setEnabled] = useState<boolean>(false)
    const [todoId, setTodoId] = useState<number | null>(null);

	const { removeTodo: removeTodoStore } = useStore((state) => state) 
   
	const queryResult = useQuery(
		['remove_todo'],
		() => TodosService.removeTodo(todoId),
		{
			enabled,
			onSuccess(res) {
				console.log('res', res)
				// if(data?.deleted === 'ok') {
					removeTodoStore(todoId as number)
					setTodoId(null)
					// }
				}
			}
		);
		
		useEffect(() => {
			if (enabled) {
				setEnabled(false);
			}
			
		}, [enabled])
		
		
		const removeTodo = (id: number) => {
			setTodoId(id)
			setEnabled(true)

			removeTodoStore(id as number)
	}

    return {
		removeTodo,
        ...queryResult,
    }
}