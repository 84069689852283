import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { IUser } from '@/shared/types/user.interface'
import { settingsService } from '@/services/settings/settings.api'
import { getUserFromStorage } from '@/services/auth/auth.helper'
import { useStore } from '@/shared/store'

export const useUserProfile = () => {
    const [enabled, setEnabled] = useState<boolean>(false)
    const [userProfile, setUserProfile] = useState<IUser | null>(null)
    const { setRefreshCount } = useStore(state => state)    

    const queryResult = useQuery(['get_user_profile'], () => settingsService.getUserProfile(), {
        enabled,
        onSuccess(res) {
            setUserProfile(res.data)
            // setRefreshCount(10)
            console.log('USER PROFILE:', res.data)
        }
    })
    useEffect(() => {
        !userProfile && fetchUserProfile()
    }, [userProfile])

    useEffect(() => {
        if (enabled) {
            setEnabled(false)
        }
    }, [enabled])

    const fetchUserProfile =  async () => {
        const dataUser = await getUserFromStorage()
        if (Object.keys(dataUser).length) {
            setUserProfile(dataUser)
            return
        }
        setEnabled(true)
        return
    }


    return {
        userProfile,
        fetchUserProfile,
        ...queryResult
    }
}
