import { View, Pressable, Image } from 'react-native'
import React, { Dispatch, FC } from 'react'
import Icon from '../icon/Icon'
import { useColorScheme } from 'nativewind'
import { useColors } from '@/hooks/useColors'
import { EThreadLabels } from '@/shared/store/enum'
import { useStore } from '@/shared/store'
import Tooltip from '../tooltip/Tooltip'
import { TThreadsCategories } from '@/shared/store/types'
import { CircleCheckBig } from 'lucide-react'

interface IProps {
    threadId: string
    visibleScive: Dispatch<React.SetStateAction<boolean>>
    isVisible: boolean
    removeThread: () => void
    updateThread: (threadId: string, label: string) => void
    category: TThreadsCategories
    isNew: boolean
}

const RowActions: FC<IProps> = ({ threadId, isNew, visibleScive, isVisible, removeThread, updateThread }) => {
    const { colorScheme } = useColorScheme()
    const { color } = useColors()
    const { filteredThreadsTabCurrent, filteredThreads } = useStore((state) => state)

    const unreadBase = filteredThreads.filter(
        (thread) => thread.id === threadId && thread.messages[0].metadata.labels.includes('UNREAD')
    )

    return (
        <View className='right-0 pr-base-x1 z-20 flex-row items-center' style={{ gap: 8 }}>
            <Pressable className='p-base-x1' onPress={() => visibleScive(!isVisible)}>
                <Tooltip placement='scive-conteiner' content='Scive power'>
                    <Image
                        source={require(`@/assets/icons/scive-${colorScheme}.svg`)}
                        className='h-base-x5 w-base-x5'
                    />
                </Tooltip>
            </Pressable>

            <Pressable onPress={() => updateThread(threadId, EThreadLabels.UNREAD)}>
                <Tooltip placement='scive-conteiner' content='Mark as read'>
                    {!isNew ? (
                        <Icon
                            name={!!unreadBase.length ? 'MailOpen' : 'Mail'}
                            size={20}
                            color={color.black}
                            className='p-base-x1'
                        />
                    ) : (
                        <CircleCheckBig size={20} stroke='black'/>
                        
                    )}
                </Tooltip>
            </Pressable>
            {!isNew && (
                <>
                    <Pressable onPress={() => updateThread(threadId, EThreadLabels.SPAM)}>
                        <Tooltip placement='scive-conteiner' content='Unsudscripe'>
                            <Icon name={'XOctagon'} size={20} color={color.black} className='p-base-x1' />
                        </Tooltip>
                    </Pressable>
                    <Pressable
                        onPress={
                            filteredThreadsTabCurrent === 'trash' || filteredThreadsTabCurrent === 'spam'
                                ? removeThread
                                : () => updateThread(threadId, EThreadLabels.TRASH)
                        }>
                        <Tooltip placement='scive-conteiner' content='Delete'>
                            <Icon name={'Trash2'} size={20} color={color.black} className='p-base-x1' />
                        </Tooltip>
                    </Pressable>
                </>
            )}
        </View>
    )
}

export default RowActions
