import { FC } from 'react'
import { View } from 'react-native'
import { Body, Header, Loader, Typography } from '@/components/ui'
import TodoList from './TodoList'
import { StyledProps } from 'nativewind'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'
import { IUpdateTodo } from '@/shared/types/todo.interface'
import { useStore } from '@/shared/store'

interface IProps {
    className?: StyledProps<string>
    isLoading?: boolean | null
    addTask: (todo: IUpdateTodo) => void
}
const AddTodoList: FC<IProps> = ({ className, isLoading, addTask }) => {
	const { todoSuggestions } = useStore()
	const { text } = useColors()

	return (
        <View className={cn('pt-base-x5 pb-base-x2 pl-base-x1 justify-between', className)}>
            <Header title='Add to To-Do list' subtitle='' />
            <Body className='pl-base-x2 pr-base-x2 mb-base-x4 pb-0'>
                {!isLoading ? (
                    !todoSuggestions ? (
                        <Typography variant='body' className={text.inactiveText}>
                            Here you can add important things from executive summary to your tasks
                        </Typography>
                    ) : (
                        <TodoList todos={todoSuggestions} addTask={addTask} />
                    )
                ) : (
                    <Loader text='Generate todos' />
                )}
            </Body>
        </View>
    )
}

export default AddTodoList