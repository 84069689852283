import { DashboardService } from '@/services/dashboard'
import { useStore } from '@/shared/store'
import { IGenerateDigestItemResult, IGenerateDigestResult } from '@/shared/types/dashboard.interface'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useApiGetDigestItem } from './useApiGetDigestItem.api'
import { useApiGetTodoSuggestions } from './useApiGetTodoSuggestions.api'
import { useApiGetHighlights } from './useApiGetHighlights.api'
import { useApiGetDigestItems } from './useApiGetDigestItems.api'
import { useApiGetDigestItemDetails } from './useApiGetDigestItemDetails.api'

export const useApiAsyncTasks = () => {
    const { setFetchDigests, setGenerateDigests, setAsyncTasks, asyncTaskId, setDigest, threads } = useStore()

    const { fetchDigestItem } = useApiGetDigestItem()
    const { fetchTodoSuggestions } = useApiGetTodoSuggestions()
    const { fetchHighlights } = useApiGetHighlights()
    const { fetchDigestItems } = useApiGetDigestItems()
    const { fetchGetDigestItemDetails } = useApiGetDigestItemDetails()

    const [enabled, setEnabled] = useState<boolean>(false)

    const queryResult = useQuery(['get_llm_async_tasks'], () => DashboardService.getAsyncTask(asyncTaskId!), {
        enabled,
        onSuccess(res) {
            if (res.data?.status === 'SUCCESS') {
                console.log('get_llm_async_tasks', res)
                setEnabled(false)
                setGenerateDigests(false)

                if (res.data?.name === "generate_digest_item_details") {
                    // fetchDigestItem(res.data.result as IGenerateDigestItemResult)
                    // fetchGetDigestItemDetails()
                    // fetchDigestItems()
                    // fetchTodoSuggestions()
                    // fetchHighlights()

                    setDigest(res.data.result as IGenerateDigestItemResult, threads) // !TEST
                }

                if (res.data?.name === "generate_digest") {
                    setFetchDigests(true)
                    setAsyncTasks(res.data.result as IGenerateDigestResult)
                }
            }
            if (res.data?.status === 'PENDING') {
                setEnabled(true)
            }
            if (res.data?.status === 'ERROR') {
                setGenerateDigests(true)
                setEnabled(false)
            }
        }
    })

    useEffect(() => {
        if (asyncTaskId && enabled) {
            fetchApiAsyncTasks()
        }
    }, [asyncTaskId])

    useEffect(() => {
        if (enabled) {
            setEnabled(false)
        }
    }, [enabled])

    const fetchApiAsyncTasks = () => {
        setEnabled(true)
    }

    return {
        fetchApiAsyncTasks,
        ...queryResult
    }
}
