import { FC } from 'react'
import { View } from 'react-native'
import { AddTodoList, DetailedSummary, Highlights } from '@/components/ui'
import cn from 'clsx'
import { useMailPosition } from '@/context/MailPositionContext'
import { useColors } from '@/hooks/useColors'

interface IProps {
    id: string | null
}

const ScivePowerContainer: FC<IProps> = ({ id }) => {
	const { bg } = useColors()
	const { position } = useMailPosition()

	return (
        <View
            className={cn(
                'flex-col border-x-8 border-b-8 border-sky-50',
                bg.white,
                position === 'horizontal' && 'flex-row'
            )}>
            <DetailedSummary
                className={cn(
                    ' border-t border-r border-b-0  border-sky ',
                    position === 'vertical' && 'border-r-0'
                )}
                detailedSummary={[]}
                messageId={id}
            />
            <View className={cn('flex-row overflow-hidden ', position === 'vertical' && ' max-h-[248px]')}>
                <AddTodoList
                    todo={[]}
                    className={cn(
                        ' flex-1 border-t border-r border-sky',
                        position === 'horizontal' && 'max-w-[360px]'
                    )}
                />
                <Highlights
                    className='flex-1 border-t border-t-sky w-[248px]  overflow-hidden'
                    highlights={[]}
                />
            </View>
        </View>
    )
}

export default ScivePowerContainer
