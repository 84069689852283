import { DashboardService } from '@/services/dashboard'
import { useStore } from '@/shared/store'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useApiAsyncTasks } from './useApiAsyncTasks.api'

export const useApiGenerateDigests = () => {
    const {
        digests,
        generateDigests,
        refreshCount,
        setLoadingAsyncTask,
        setAsyncTaskId,
    } = useStore((state) => state)
    
    const { fetchApiAsyncTasks } = useApiAsyncTasks()
    const [enabled, setEnabled] = useState<boolean>(false)
    
    
    const queryResult = useQuery(['get_llm_generate_digests'], () => DashboardService.getGenerateDigests(), {
        enabled,
        async onSuccess(res) {
            console.log('get_llm_generate_digests', res)
            if(res.data && res.data.id) {
                setAsyncTaskId(res.data.id)
                await fetchApiAsyncTasks()
                setLoadingAsyncTask(true)
                
            }
        }
    })

    useEffect(() => {
        if (!digests.length && generateDigests && refreshCount > 0) {
            fetchApiGenerateDigests()
        }
    }, [digests])

    useEffect(() => {
        if (enabled) {
            setEnabled(false)
        }
    }, [enabled])

    const fetchApiGenerateDigests = () => {
        setEnabled(true)
    }

    return {
        fetchApiGenerateDigests,
        ...queryResult
    }
}
