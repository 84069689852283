export const SERVER_URL = 'https://test.scive.ai'
export const API_VERSION = 'v1'
export const API_URL = `${SERVER_URL}/api`

export const SERVER_WS_URL = 'wss://test.scive.ai'
export const API_WS_URL = `${SERVER_WS_URL}/api`

export const getAuthUrl_Old = (string = '') => `${API_URL}/${API_VERSION}/auth${string}`
export const getMessagesUrl_Old = (string = '') => `${API_URL}/${API_VERSION}/messages${string}`
export const getThreadsUrl_Old = (string = '') => `${API_URL}/${API_VERSION}/threads${string}`
export const getTodosUrl_Old = (string = '') => `${API_URL}/${API_VERSION}/todos${string}`

// WS
export const wsDemoUrl = (string = '') => `${API_URL}/_demo/channel${string}`
export const wsUrl = (string = '') => `${API_WS_URL}/channel${string}`

export const SERVER_URL_V2 = 'https://flow-788974002465.europe-west4.run.app'
export const API_URL_V2 = `${SERVER_URL_V2}/api`

export const getAuthUrl = (string = '') => `${API_URL_V2}/${API_VERSION}/auth${string}`
export const getThreadsUrl = (string = '') => `${API_URL_V2}/${API_VERSION}/threads${string}`
export const getLabelsUrl = (string = '') => `${API_URL_V2}/${API_VERSION}/labels${string}`
export const getMessagesUrl = (string = '') => `${API_URL_V2}/${API_VERSION}/messages${string}`
export const getLlmUrl = (string = '') => `${API_URL_V2}/${API_VERSION}/llm${string}`
export const getLlmAsyncTasksUrl = (string = '') => `${API_URL_V2}/${API_VERSION}/async-tasks${string}`
export const getTodosUrl = (string = '') => `${API_URL_V2}/${API_VERSION}/todos${string}`
