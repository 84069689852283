import { FC, useLayoutEffect, useRef, useState } from 'react'
import { Button, Messages, Typography } from '@/components/ui'
import {
	NativeScrollEvent,
	NativeSyntheticEvent,
	ScrollView
} from 'react-native'
import { useColors } from '@/hooks/useColors'
import cn from 'clsx'

const Content: FC = () => {
	const scrollViewRef = useRef<ScrollView | null>(null)
	const [count, setCount] = useState(6)
	const [visibleScrollDown, setVisibleScrollDown] = useState(false)
	const { bg, text, color } = useColors()

	const scrollDown = () => {
		if (scrollViewRef.current) {
			scrollViewRef.current.scrollToEnd({ animated: false })
			setCount(0)
		}
	}

	const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
		const currentScrollY = event.nativeEvent.contentOffset.y
		const scrollViewHeight = event.nativeEvent.layoutMeasurement.height
		const contentHeight = event.nativeEvent.contentSize.height
		const isAtBottom = currentScrollY >= contentHeight - scrollViewHeight

		if (isAtBottom) {
			setVisibleScrollDown(false)
		} else {
			setVisibleScrollDown(true)
		}
	}

	useLayoutEffect(() => {
		if (scrollViewRef.current) {
			// Прокрутка ScrollView в самый низ
			scrollViewRef.current.scrollToEnd({ animated: false })
		}
	}, [])

	return (
		<>
			<ScrollView
				onScroll={handleScroll}
				scrollEventThrottle={60}
				className='pl-base-x4 relative flex-1 mr-base-x2'
				ref={scrollViewRef}
			>
				<Messages messagesType='digests' />
			</ScrollView>
			{visibleScrollDown && (
				<Button
					className={cn(
						'absolute bottom-base-x6 right-[25px] z-20 w-base-x10 h-base-x10 rounded-base-x16 flex-col items-center justify-center shadow-sm',
						bg.scrolldownCounter
					)}
					onPress={scrollDown}
					icon='ChevronDown'
					colorIcon={color.black}
					noMargin
				>
					{count ? (
						<Typography
							variant='label-date'
							className={cn(
								'absolute bottom-[30px] left-[-3px] bg-red text-center pl-base-x2 pr-base-x2 pb-base-x1 pt-base-x1 rounded-[100px] text-white w-[45px]',
								text.white
							)}
						>
							{count}
						</Typography>
					) : null}
				</Button>
			)}
		</>
	)
}
export default Content
