import { FC, useState } from 'react'
import { View } from 'react-native'
import { Button, ButtonPlain, Dropdown, InputSearch, Tooltip } from '@/components/ui'
import cn from 'clsx'
import { useTypedNavigation } from '@/hooks/useTypedNavigation'
import { useMailPosition } from '@/context/MailPositionContext'
import { Pressable } from 'react-native-web-hover'
import { useTypedRoute } from '@/hooks/useTypedRoute'
import { useColors } from '@/hooks/useColors'
import { useStore } from '@/shared/store'
import { EFilterOptions, EThreadLabels } from '@/shared/store/enum'
import { useGetThreads, useUpdateThreadLabels } from '../../api'

interface IProps {}


const MailHeader: FC<IProps> = ({ }) => {
    const { bg, text, color } = useColors()
    const { params } = useTypedRoute<'Inbox'>()
    const path = params?.path
    const { navigate } = useTypedNavigation()

    const { position, setPosition } = useMailPosition()
    const [dropdownVisible, setDropdownVisible] = useState(false)

    const { fetchThreads } = useGetThreads()

    const {
        showCompose,
        setShowCompose,
        selectedAllThreads,
        setSelectAllThreads,
        selectedThreads,
        selectedThreadId,
        updateDropdownSelectedThreads,
        removeSelectedThreads,
        unreadThreads,
        searchThreadValue,
        searchThreads,
        setChatType,
        updateThreadLabels,
    } = useStore((state) => state)

    const { fetchUpdateThreadLabels } = useUpdateThreadLabels()

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible)
    }
    const closeDropdown = () => {
        setDropdownVisible(false)
    }

    const handlerUpdateSelectedThreads = async (label: EThreadLabels) => {
        await updateThreadLabels(label)
        await fetchUpdateThreadLabels(label as EThreadLabels)
    }
    
    const handleDropdown = async (label: string) => {
        if (label.toLowerCase() === 'all') return setSelectAllThreads()

        await updateThreadLabels(label.toLowerCase() as EFilterOptions)
        await fetchUpdateThreadLabels(label.toLowerCase() as EFilterOptions)
    }
    
    return (
        <View className={cn('flex-row justify-between items-center relative z-20 mb-2')}>
            <View className='flex-row justify-between items-center' style={{ gap: 24 }}>
                <View className='ml-[2px]'>
                    {!!selectedThreadId && position === 'horizontal' ? (
                        <Tooltip placement='bottom-right' content='Back'>
                            <Button
                                icon='MoveLeft'
                                sizeIcon={20}
                                colorIcon={color.black}
                                className='p-base-x2 rounded-base-x2'
                                styleOnHover={bg.hoverIconSurface}
                                onPress={() => navigate('Inbox', { path: path, id: null })}
                            />
                        </Tooltip>
                    ) : (
                        <Pressable>
                            {({ hovered }) => (
                                <View
                                    style={{
                                        //@ts-ignore
                                        transition: 'all 0.3s ease-out'
                                    }}
                                    className={cn(
                                        'flex-row items-center rounded-base-x2',
                                        (hovered || !!selectedThreads.length) && bg.hoverIconSurface
                                    )}>
                                    <Tooltip placement='bottom' content='Select all'>
                                        <Button
                                            icon={
                                                selectedAllThreads === 'all'
                                                    ? 'CheckSquare'
                                                    : selectedAllThreads === 'some'
                                                    ? 'MinusSquare'
                                                    : !selectedAllThreads
                                                    ? 'Square'
                                                    : ''
                                            }
                                            sizeIcon={20}
                                            colorIcon={color.black}
                                            className='p-base-x2'
                                            onPress={() => setSelectAllThreads()}
                                        />
                                    </Tooltip>
                                    <Dropdown
                                        left={-35}
                                        setValue={handleDropdown}
                                        variant='body'
                                        isVisible={dropdownVisible}
                                        onClose={closeDropdown}
                                        options={
                                            path === 'spam'
                                                ? [
                                                      { text: 'All' },
                                                      { text: 'Read' },
                                                      { text: 'Unread' },
                                                      { text: 'Important' },
                                                      { text: 'Not important' },
                                                      { text: 'All not spam' }
                                                  ]
                                                : [
                                                      { text: 'All' },
                                                      { text: 'Read' },
                                                      { text: 'Unread' },
                                                      { text: 'Important' },
                                                      { text: 'Not important' },
                                                      { text: 'Spam' }
                                                  ]
                                        }
                                        button={
                                            <Tooltip placement='bottom' content='Select'>
                                                <Button
                                                    onPress={toggleDropdown}
                                                    icon='ChevronDown'
                                                    colorIcon={color.black}
                                                    sizeIcon={20}
                                                    className='p-base-x2'
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </View>
                            )}
                        </Pressable>
                    )}
                </View>
                {!!selectedThreads.length && (
                    <View className='flex-row items-center' style={{ gap: 8 }}>
                        {[undefined, 'sent'].includes(path) && (
                            <>
                                <Tooltip placement='bottom' content='Mark as read'>
                                    <Button
                                        icon={!!unreadThreads.length ? 'MailOpen' : 'Mail'}
                                        sizeIcon={20}
                                        colorIcon={color.black}
                                        className='p-base-x2 rounded-base-x2'
                                        styleOnHover={bg.hoverIconSurface}
                                        onPress={() => handlerUpdateSelectedThreads(EThreadLabels.UNREAD)}
                                    />
                                </Tooltip>
                                <Tooltip placement='bottom' content='Report spam'>
                                    <Button
                                        icon='AlertOctagon'
                                        colorIcon={color.black}
                                        sizeIcon={20}
                                        className='p-base-x2 rounded-base-x2'
                                        styleOnHover={bg.hoverIconSurface}
                                        onPress={() => handlerUpdateSelectedThreads(EThreadLabels.SPAM)}
                                    />
                                </Tooltip>
                                <Tooltip placement='bottom' content='Delete'>
                                    <Button
                                        icon='Trash2'
                                        colorIcon={color.black}
                                        sizeIcon={20}
                                        className='p-base-x2 rounded-base-x2'
                                        styleOnHover={bg.hoverIconSurface}
                                        onPress={() => handlerUpdateSelectedThreads(EThreadLabels.TRASH)}
                                    />
                                </Tooltip>
                            </>
                        )}
                        {path === 'sent' && (
                            <Button
                                icon='FolderSymlink'
                                colorIcon={color.black}
                                sizeIcon={20}
                                className='p-base-x2 rounded-base-x2'
                                styleOnHover={bg.hoverIconSurface}
                                onPress={() => handlerUpdateSelectedThreads(EThreadLabels.INBOX)}
                            />
                        )}
                        {path === 'spam' && (
                            <>
                                <ButtonPlain onPress={() => updateDropdownSelectedThreads('not spam')}>
                                    Not spam
                                </ButtonPlain>
                                <Tooltip placement='bottom' content='Mark as unread'>
                                    <ButtonPlain onPress={removeSelectedThreads}>Delete forever</ButtonPlain>
                                </Tooltip>
                                <Button
                                    icon='MailCheck'
                                    colorIcon={color.black}
                                    sizeIcon={20}
                                    className='p-base-x2 rounded-base-x2'
                                    styleOnHover={bg.hoverIconSurface}
                                    onPress={() => handlerUpdateSelectedThreads(EThreadLabels.UNREAD)}
                                />
                            </>
                        )}
                        {path === 'trash' && (
                            <>
                                <ButtonPlain onPress={removeSelectedThreads}>Delete forever</ButtonPlain>
                                <Button
                                    icon='MailCheck'
                                    colorIcon={color.black}
                                    sizeIcon={20}
                                    className='p-base-x2 rounded-base-x2'
                                    styleOnHover={bg.hoverIconSurface}
                                    onPress={() => handlerUpdateSelectedThreads(EThreadLabels.UNREAD)}
                                />
                                <Button
                                    icon='ArchiveRestore'
                                    colorIcon={color.black}
                                    sizeIcon={20}
                                    className='p-base-x2 rounded-base-x2'
                                    styleOnHover={bg.hoverIconSurface}
                                    onPress={() => handlerUpdateSelectedThreads(EThreadLabels.INBOX)}
                                />
                                <Button
                                    icon='AlertOctagon'
                                    colorIcon={color.black}
                                    sizeIcon={20}
                                    className='p-base-x2 rounded-base-x2'
                                    styleOnHover={bg.hoverIconSurface}
                                    onPress={() => handlerUpdateSelectedThreads(EThreadLabels.SPAM)}
                                />
                            </>
                        )}
                    </View>
                )}
                {!selectedThreadId && path === 'spam' && (
                    <Tooltip placement='bottom' content='Refresh'>
                        <Button
                            icon='RefreshCcw'
                            colorIcon={color.black}
                            sizeIcon={20}
                            className='p-base-x2 rounded-base-x2'
                            styleOnHover={bg.hoverIconSurface}
                            onPress={fetchThreads}
                        />
                    </Tooltip>
                )}
                <InputSearch
                    onChange={searchThreads}
                    value={searchThreadValue}
                    placeholder='Search mail'
                    className='w-[440px]'
                />
            </View>
            <View className='flex-row' style={{ gap: 8 }}>
                <Button
                    icon='Pencil'
                    sizeIcon={20}
                    styleOnHover='bg-sky-500'
                    onPress={() => (setShowCompose(true), setChatType('compose'))}
                    variant='button-default'
                    disabled={showCompose}
                    colorIcon={showCompose ? color.inactiveIcons : color.black}
                    className={cn(
                        'bg-sky pl-base-x3 pr-base-x4 rounded-base-x3 h-9 mr-6',
                        showCompose && bg.inactiveSurface
                    )}
                    classNameText={cn(showCompose ? text.disabledText : text.white)}>
                    Compose
                </Button>
                {/* {position === 'vertical' ? (
                    <Button
                        icon='LayoutList'
                        sizeIcon={24}
                        colorIcon={color.black}
                        styleOnHover={bg.hoverIconSurface}
                        className='p-base-x2 rounded-base-x2'
                        onPress={() => togglePosition()}
                    />
                ) : (
                    <Button
                        icon='Menu'
                        sizeIcon={24}
                        colorIcon={color.black}
                        styleOnHover={bg.hoverIconSurface}
                        className='p-base-x2 rounded-base-x2'
                        onPress={() => setPosition('vertical')}
                    />
                )} */}
            </View>
        </View>
    )
}

export default MailHeader
