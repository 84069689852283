import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { LayoutChangeEvent, View } from 'react-native'
import { ITooltip } from './tooltip.interface'
import { Typography } from '@/components/ui'

const Tooltip: FC<PropsWithChildren<ITooltip>> = ({ placement, content, children, timeout }) => {
    const [isVisibleTooltip, setVisibleTooltip] = useState(false)
    const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 })

    let timeoutId: string | number | NodeJS.Timeout | undefined

    const handleMouseEnter = () => {
        if (timeout) {
            timeoutId = setTimeout(() => setVisibleTooltip(true), timeout)
        } else {
            setVisibleTooltip(true)
        }
    }

    const handleMouseLeave = () => {
        clearTimeout(timeoutId)
        setVisibleTooltip(false)
    }

    const onPositionLayout = (event: LayoutChangeEvent) => {
        const { x, y, width, height } = event.nativeEvent.layout
        let left = 0
        let top = 0

        switch (placement) {
            case 'top':
                left = -10
                top = y - height - 10
                break
            case 'top-compose-clock':
                left = x - width - 5
                top = y - 30
                break
            case 'top-compose-send':
                left = 0
                top = y - 30
                break
                
            case 'bottom':
                left = x - width / 2
                top = y + height + 10
                break

            case 'left':
                left = x - width - width * 2
                top = y + height / 4
                break

            case 'right':
                left = x + width
                top = y + height / 5
                break
            case 'bottom-left':
                left = x - width - width
                top = y + height + 5
                break
            case 'bottom-right':
                left = x + width / 10
                top = y + height + 5
                break
            case 'scive-conteiner':
                left = x - width * 2
                top = y - height - 10
                break
            case 'dashboard-right':
                left = x + width + 10
                top = y + height / 5
                break
            case 'icon-important':
                left = x - width * 11
                top = y + height / 4
                break
            default:
                break
        }

        setTooltipPosition({ left, top })
    }

    return (
        <View
            className='relative text-white z-[10000]'
            onPointerEnter={() => handleMouseEnter()}
            onPointerLeave={() => handleMouseLeave()}>
            <View onLayout={onPositionLayout}>{children}</View>
            {isVisibleTooltip && (
                <View
                    className='absolute bg-gray-800 rounded-base-x2 opacity-100 pl-base-x2 pr-base-x2 pt-[4px] pb-[4px]'
                    style={{ position: 'absolute', left: tooltipPosition.left, top: tooltipPosition.top }}>
                    <Typography variant='calout' nowrap className='text-white'>
                        {content}
                    </Typography>
                </View>
            )}
        </View>
    )
}

export default Tooltip
